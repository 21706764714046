<template>
  <b-row>
    <b-col  sm="5">
      <b-button v-if="!isLoginAs" type="link" variant="primary" class="w-100 mb-2" :to="{ name: 'participant-messages' }">
        <feather-icon icon="MessageSquareIcon" size="16" class="mr-50" />
        Messages
      </b-button>
      <b-card tag="article" >
        <b-row class="pb-0">
          <b-col class="d-flex flex-column justify-content-between">
            <b-avatar square size="50" variant="light-dark">
              <img  :src="mySvg" />
              </b-avatar> 
            <div>
              
              <b-card-title class="text-truncate" style="margin-bottom: 3px">
                Learning Progress
              </b-card-title>
              <p class="font-small-3">
                Overall time spent
              </p>
            </div>
            <b-card-text class="mb-0 mt-2 h1 font-weight-bold">
              {{estimatedOverall}}
            </b-card-text>
          </b-col>
          <b-col class="p-0">
            <div id="chart">
              <apexchart type="radialBar" :options="activeParticipantPercentageChartOption"
                :series="overallTimeSpentPercentage" />
            </div>
          </b-col>
        </b-row>
      </b-card>
      <b-card v-if="resources.total">
        <b-row align-v="start" class="d-none d-sm-flex">
          <b-col sm="2" class="pr-0 ">
            <b-avatar square size="50" variant="light-dark">
              <feather-icon icon="BookOpenIcon" size="25" />
            </b-avatar>
          </b-col>
          <b-col sm="9" class="pl-0" >
            <b-card-title class="text-truncate m-0">
              <b-row>
                <b-col >
                  Resources
                  <p class="font-small-3 m-0">
                    Resources available
                  </p>
                </b-col>
                <b-col class="d-flex flex-row-reverse align-items-center">
                  <p class="font-large-1 font-weight-bold d-flex m-0 flex-row-reverse align-items-center">
                    {{ resources.total }}
                  </p>
                </b-col>
              </b-row>
            </b-card-title>
           
            
          </b-col>
        </b-row>

        <b-card-text class="mt-2 text-center">
          <b-button class="w-100" type="link" :to="{ name: 'participant-resources' }" variant="primary">
            View resources
          </b-button>
        </b-card-text>
      </b-card>
    </b-col>
    <b-col sm="7">
      <b-alert variant="dark" class="d-flex p-1 align-items-center" show>
        <b-avatar square size="40" variant="white" class="mr-1">
          <feather-icon icon="FileTextIcon" size="25" class="color-black"/>
        </b-avatar>
        <div >
          <p>Modules will open in a new window. Please make sure pop-ups are enabled to view them.</p>
        </div>
      </b-alert>
      <training-list />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardText,
  BButton,
  BLink,
  BProgress,
  BAvatar,
  BAlert
} from "bootstrap-vue";
import {
  AOM_GREEN_COLOR
} from "@/libs/utils";
import TrainingList from "../../training/TrainingList.vue";
import KeyDates from "@/views/components/common/KeyDates.vue";
import { makeErrorToast } from "@/libs/utils";
import Box from "@/assets/images/icons/box.svg";
import { loaderTypes, programTypes, userRoles } from '@models';
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import { mapGetters } from "vuex"
import trainingsService from "@/services/trainingsService";
import MyAvailibility from '@/participants/mentoring-on-demand/partials/MyAvailibility.vue';
import { trainingStatus } from "@/models";
export default {
  name: 'TrainingDashboard',
  components: {
    TrainingList,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardText,
    BButton,
    KeyDates,
    BLink,
    AomSkeletonLoader,
    BProgress,
    BAvatar,
    BAlert,
    MyAvailibility
  },
  data() {
    return {
      row: [],
      allCoursesComplete: true,
      mySvg: Box,
      trainingUrl: "",
      defaultChartColor: "#FFA500",
      defaultGreenChartColor: AOM_GREEN_COLOR,
      overAllTimeSpent: 0,
      overAllEstimated: 0,
      defaultChartOptions: {
        chart: {
          type: 'radialBar',
          renderTo: 'container',
          spacingTop: 0,
          spacingBottom: 0,
          spacingLeft: 0,
          spacingRight: 0,
          offsetY: 0,
        },
        plotOptions: {
          radialBar: {
            size: '50%',
            hollow: {
              margin: 0,
              size: '70%',
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: 'front',
            },
            track: {
              strokeWidth: '60%',
              margin: 0,
            },
            dataLabels: {
              showOn: 'always',
              name: {
                show: false,
              },
              value: {
                formatter: function(val) {
                  return parseInt(val) + "%";
                },
                color: '#003366',
                fontSize: '20px',
                lineHight: '1',
                show: true,
                offsetY: 6,
                offsetX:0,
              }
            }
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: 'horizontal',
            shadeIntensity: 0.4,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 53, 91]
          },
        },
      },
    }
  },
  
  computed: {
    ...mapGetters("participants", ['isApplicationAnswersLoading', 'applicationMentee', 'applicationMentor']),
    ...mapGetters('profile', ['isUserMentee', 'isUserMentor']),
    isLoginAs() {
      const userData = this.profile;
      if (userData?.logged_in_by && Number(userData?.logged_in_by.id) !== Number(userData.id)) {
        return true;
      }
      return false;
    },
    appCurrentProgram() {
      return this.$store.getters['app/currentProgram'];
    },

    program() {
      return this.$store.getters['participants/program'];
    },

    programTimezone() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    },

    programs() {
      return this.$store.getters['participants/programs'];
    },
    trainings() {
      return this.$store.getters['participants/trainings'];
    },

    resources() {
      return this.$store.getters['participants/resources'];
    },

    participants() {
      return this.$store.getters['participants/participants'];
    },

    messages() {
      return this.$store.getters['participants/messages'];
    },

    profile() {
      return this.$store.getters['profile/profile'];
    },
    activeParticipantPercentageChartOption() {
      return { ...this.defaultChartOptions, colors: [this.defaultGreenChartColor] };
    },
    overallTimeSpentPercentage() {
      if(!this.overAllTimeSpent){
        return [0];
      }

      if(this.allCoursesComplete) {
        return [100];
      }
      
      if(this.overAllTimeSpent > this.overAllEstimated) {
        return [99];
      }

      return [this.overAllTimeSpent / this.overAllEstimated * 100];
    },
    estimatedOverall() {
      const estimatedTime = this.overAllTimeSpent || 0;
      if (estimatedTime === 0) {
        return '0 minute';
      }
      // Don't show if seconds less than 60
      if (this.overAllTimeSpent < 60) {
        return '0 minute';
      }
      return `${Math.round(estimatedTime / 60)} minutes`;
    },

  },
  created() {
    this.loadItems();
  },
  methods: {
    onContactChampion() {
      this.$router.push({ name: this.$route.name, query: { isContactProgramManager: 1 } });
    },
    async loadItems() {
      try {
        this.isLoading = true;
        const response = await trainingsService.getUserTrainings(
          this.program.id,
          this.profile.id
        );
        this.total = response.data.total;
        response.data.items.map(item => {
          this.overAllEstimated += item.estimated_seconds || 0;
            if(item.program_training_users.length <= 0) {
              return null;
            }
            const trainingUser = item.program_training_users[
            item.program_training_users.length - 1
            ];
            this.overAllTimeSpent += trainingUser.total_seconds_tracked || 0;
        
            if(!trainingUser?.status?.id || trainingUser?.status?.id !== trainingStatus.COMPLETE) {
              this.allCoursesComplete = false
            }
        });
       
      } catch (e) {
        this.$toast(makeErrorToast("Training list not loaded."));
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
  setup() {
    return {
      AOM_GREEN_COLOR,
      loaderTypes
    };
  },
};
</script>

<style lang="scss" scoped>
.h-50px {
  height: 50px;
}

.py-8px {
  padding-top: 8px;
  padding-bottom: 8px;
}

::v-deep .default-chart-color {
  .progress-bar {
    background-color: #FFA500 !important;
  }
}

::v-deep .default-green-chart-color {
  .progress-bar {
    background-color: #9bcc65 !important;
  }
}
.color-black{
  color: #373b3e
}
</style>